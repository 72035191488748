import './PageNotFoundStyles.scss';

const PageNotFound = () => {
    return (
        <div className="page-not-found-container">
            <div className="page-not-found-header">Page not found</div>
            <div className="page-not-found-description">
                The page you are looking for does not exist or has been moved.
            </div>
        </div>
    );
};

export default PageNotFound;
