import './HomeStyles.scss';

const Home = () => {
    return (
        <div className="home-container">
            <h1 className="home-header">Trainer Tasks</h1>
            <div className="home-description">Website coming soon.</div>
        </div>
    );
};

export default Home;
